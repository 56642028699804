.lcontainer{
    
    
    width: 530px;
    height: 325px;
    background-color: #F2F2F2;
    padding: 30px;
    box-shadow: 1px 1px 1px  black;
    border-radius: 20px;

    }

    .lbtnn{
        background-color: green;
        width: 150px;
        height: 45px;
        margin-top: 20px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        color: white;
        font-size: 20px;
        font-family: sans-serif;
    }