
  .footerContainers{
    height: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    color: aliceblue;
    background-color: black;
   
  }

  .fone{
   grid-column: 1/3;
   background-color: black;
  }

  .ftwo{
   grid-column: 3/6;
  }

  .fthree{
   grid-column: 6/8;
  }

  .ffour{
   grid-column: 8/10;
  }

  .ffive{
   grid-column: 10/-1;
  }


  .fsix{
    grid-column: 1/-1;
    margin-top: 80px;
    background-color: black;
   
   }

  ul{
      display:grid;
  }

  ul li{
      padding: 10px;
  }

  .iosNandroid{
    display:grid;
  }

  .uui .fa {
    padding: 20px;
    font-size: 30px;
    height: 56px;
    width: 60px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 6px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  .fa-twitter {
    background: #55ACEE;
    color: white;
  }
  
  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }
  
  .fa-youtube {
    background: #bb0000;
    color: white;
  }