
.addtocartimg{
  height: 100px;
  width: 150px;
}

.adddetails{
  margin-left: 40px;
}

.addtocartone{
  margin-top: 40px;

  display: flex;

}

.addbtnn{
  margin-left: 350px;
  width: 200px;
  height: 40px;
  margin-top: 10px;
  background-color: orange;
  border: 1px solid whitesmoke;
}
.div1{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    }
.Addcontainer{
    
    
    width: 800px;
    margin: 30px;
    height: auto;
    background-color: #F2F2F2;
    padding: 30px;
    box-shadow: 5px black;
    border-radius: 20px;
    border: 1px solid gray;

    }



.peraaddtocart{
    text-align: justify;
}





    .wcenter{
        text-align: center;
    }

      
    
      .imgg{
        height: 200px;
        width: 200px;
        margin-left: 300px;
      }

      .titlee{
          background-color: black;
          margin-top: 15px;
          padding-left: 30px;
          color: aliceblue;
          display: flex;
      }

      .titlee h2{
          text-align: left;
      }


      .cuponbtn{
          margin-top: 10px;
          
       
        background-color: green;
        
        border-radius: 7px;
        height: 40px;
        width: 100px;
    }

      .checkoutbtnn{
         margin-top: 10px;
         margin-left: 510px;
          border: none;
          background-color: green;
         
          border-radius: 7px;
          height: 40px;
          width: 100px;
      }

      .llk{
          display: flex;
          
      }