
.div1{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    

     
  
}
.rcontainerss{
    
    
    width: 530px;
    height: 500px;
    background-color: #F2F2F2;
    padding: 30px;
    box-shadow: 1px 1px 1px  black;
    border-radius: 20px;

    }

.name{
    font-size: 20px;


}

.text{
    width: 300px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 15px;


}
.text:hover{
    background-color: #90EE90;
}
.regbtn{
    background-color: green;
    width: 150px;
    height: 45px;
    margin-top: 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
}
