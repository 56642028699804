.orcontainerss{
    
 
  margin: 30px;
  width: auto;
  height: auto;
  background-color: #F2F2F2;
  padding: 30px;
  box-shadow: 1px 2px 4px  black;
  border-radius: 20px;

  }

  .ordericon{
    width: 30px;
    border: 2px solid red;
  }




table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even){background-color: #f2f2f2}
  
  th {
    background-color: black;
    color: white;
  }