
  

*{
    margin: 0px;
    padding: 0px;
   

    }



.div1{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    

     
  
}
.ccontainerss{
    
    
    width: 800px;
    height: auto;
    background-color: #F2F2F2;
    padding: 30px;
    box-shadow: 1px 2px 4px  black;
    border-radius: 20px;

    }

.name{
    font-size: 20px;


}

.text{
    width: 300px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 15px;


}
.text:hover{
    background-color: #90EE90;
}
.checkoutbtn{
    background-color: green;
    width: 230px;
    height: 50px;
    margin-top: 30px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-family: sans-serif;
}
.btn:hover{
    background-color: #951EE9;
    }
    h2{
        text-align: center;
        padding-bottom: 10px;
    }
    p{
        text-align: center;
        margin-top: 10px;
    }